<template lang="pug">
    div.main-container(:style="{backgroundSize:'cover', backgroundImage:'url(' + require('@/assets/img2/insigne-bg-stroke-grey.png') + ')'} ")
        div.overlay
        div.main-content(id="content")
            div.layout-px-spacing
                div.page-header
                    div.page-title
                        h3  {{ $t('teachcreategroup.reggroup') }}

                    div
                       nav.breadcrumb-one(aria-label="breadcrumb")
                          ol.breadcrumb
                                li.breadcrumb-item
                                    router-link(to="/teacher/teacherhome") {{ $t('teachcreategroup.dashboard') }}
                                li.breadcrumb-item.active(aria-current="page") {{ $t('teachcreategroup.reggroup') }}

                div.row.mt-5.pb-5.d-flex.align-items-center
                    div.col-lg-6.col-md-6.layout-spacing.mx-auto
                        div.statbox.widget.box.box-shadow
                            div.widget-header
                                div.row
                                    div.col-xl-12.col-md-12.col-sm-12.col-12.text-center
                                            h4 {{ $t('teachcreategroup.completgroup') }}
                                vue-form(:state="formstate")
                                    div.widget-content.widget-content-area

                                        div.form-group.mb-4
                                            label(for="sParentCenter") {{ $t('teachcreategroup.centergroupbel') }}
                                            select.form-control(id="sParentCenter" v-model="model.idCenter")
                                                option(value="0" selected) {{ $t('teachcreategroup.selecoption') }}
                                                option(:value=" Center.idCenter" v-for="Center in CenterList") {{ Center.sCenterName }}

                                        div.form-group.mb-4
                                            label(for="sGroupName") {{ $t('teachcreategroup.groupname') }}
                                            validate(tag="div")
                                                input.form-control(id="sGroupName" required name="sGroupName" type="text" placeholder="Nombre del grupo" v-model="model.sGroupName")
                                                field-messages.text-danger(name="sGroupName" )
                                                    div(slot="required") {{ $t('teachcreategroup.grouprequired') }}

                                        div.form-group.mb-4
                                            label(for="sKeyGroup") {{ $t('teachcreategroup.groupkey') }}
                                            input.form-control(id="sKeyGroup" name="sKeyGroup" type="text" :value="sKeyGroup" readonly)

                                        div.form-group.mb-4
                                            label(for="sDescription") {{ $t('teachcreategroup.description') }}
                                            validate(tag="div")
                                                input.form-control(id="sDescription" required name="sDescription" type="text" placeholder="Descripción" v-model="model.sGroupDescription")
                                                field-messages.text-danger(name="sDescription" )
                                                    div(slot="required") {{ $t('teachcreategroup.descriptionrequired') }}

                                        div.form-group.mb-4
                                            label(for="dStartDate")  {{ $t('teachcreategroup.statedate') }}
                                            validate(tag="div")
                                                input.form-control(id="dStartDate"  name="dStartDate" type="date" placeholder="Fecha Inicial" v-model="dStartDate")
                                                field-messages.text-danger(name="dStartDate" )
                                                    div(slot="required")  {{ $t('teachcreategroup.startedaterequired') }}

                                        div.form-group.mb-4
                                            label(for="dFinishDate")  {{ $t('teachcreategroup.stateend') }}
                                            validate(tag="div")
                                                input.form-control(id="dFinishDate"  name="dFinishDate" type="date" placeholder="Fecha Final" v-model="dFinishDate")
                                                field-messages.text-danger(name="dFinishDate" )
                                                    div(slot="required")  {{ $t('teachcreategroup.stateendrequired') }}
                                        
                                        div.form-group.mb-4
                                            div.form-check(v-for="exam in ExamsList")
                                                input.form-check-input(type="checkbox" :value="exam.idExam" :id="'chk'+exam.idExam" v-model="checkedExams")
                                                label(:for="'chk'+exam.idExam") {{ exam.sExamName }}
                                            


                                        button.btn.btn-secondary(type="button" @click="Save") {{ $t('btns.btnsend') }}
</template>
<script>
/* eslint-disable */
export default {
    name: 'CreateGroup',
    data() {
        return {
            formstate: {},
            dStartDate: new Date().toISOString().substr(0, 10),
            dFinishDate: new Date().toISOString().substr(0, 10),

            CenterList: [],
            ExamsList: [],
            checkedExams: [],
            sKeyGroup: '',
            model: {
                idTeacher:0,
                idCenter:0,
                sGroupName: '',
                sKeyGroup: '',
                sGroupDescription: '',
                dUTCStartDate: '',
                dUTCFinishDAte: '',
                GroupExams: []
            },
            
        }
    },
    methods: {
        async getCenterList() {
            await axios.get(apiURL + 'Group/getCenter?idTeacher=' + localStorage.CertIdP, { headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + localStorage.userToken
            } })
            .then( (response) => {
                this.CenterList = response.data.centersList
            } )
            .catch( (error)  => {
                console.log("error")
            } )
        },
        
        async getExamsList() {
            await axios.get(apiURL + 'Group/getAvailableExams', { headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + localStorage.userToken
            } })
            .then( (response) => {
                this.ExamsList = response.data.examsList
            } )
            .catch( (error)  => {
                console.log("error")
            } )            
        },

        async getKeyGroup() {
            await axios.get(apiURL + 'Group/getKeyGroup', { headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + localStorage.userToken
            } })
            .then( (response) => {
                this.sKeyGroup = response.data.stringValue
            } )
            .catch( (error)  => {
                console.log("error")
            } )            

        },

        async Save() {
            if(this.formstate.$invalid) {
                return;
            }
            console.log(this.sKeyGroup)

            this.openLoading('Guardando Datos ...');
            this.model.idTeacher = localStorage.CertIdP
            this.model.dUTCStartDate = this.castDate(this.dStartDate)
            this.model.dUTCFinishDAte = this.castDate(this.dFinishDate)
            this.model.sKeyGroup = this.sKeyGroup
            var arrayExams = []
            this.checkedExams.forEach(element => {
                let exam = {
                    'idExam': element,
                    'bUserCanAnswer': 'false',
                    'bUserCanRetake': 'false'
                }
                arrayExams.push(exam)
            });
            this.model.GroupExams = arrayExams
            this.model.sGroupKey = this.sKeyGroup

            await axios.post(apiURL + 'Group/Create', this.model, { headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + localStorage.userToken
            } })
            .then( (response) => {
                Swal.close();
                if(response.data.bIsCorrect == false){
                    Swal.fire({
                        icon: 'error',
                        title: 'Error',
                        text: response.data.sErrors[0],
                        footer: ''
                    })
                    return
                }
                Swal.fire('Datos Guardados correctamente')
                router.push('/teacher/teacherhome')
            } )
            .catch( (error) => {
                Swal.close();
                Swal.fire({
                    icon: 'error',
                    title: 'Error',
                    text: error,
                    footer: ''
                })
            } )
        },
        castDate(dateToCast) {
            var date = new Date(dateToCast);
            var year = date.getFullYear();
            var month = date.getMonth()+1;
            var dt = date.getDate();

            if (dt < 10) {
            dt = '0' + dt;
            }
            if (month < 10) {
            month = '0' + month;
            }

            return dt + '/' + month + '/' + year
        }
    },
    mounted() {
            
            this.checkPermisions(this.$router.currentRoute.path)
            this.getCenterList()
            this.getExamsList()
            this.getKeyGroup()
        }    
        
}
</script>